import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

import { getListItemsClassName } from '@/utils/get-list-items-class-name';
import { getModelURL } from '@/lib/datocms/utils/get-model-url';
import { Image } from '@/components/image';
import { Link } from '@/components/link';
import { Tag } from '@/components/tag';
import type { ModelCard, PageCard } from '@/lib/types';

const LinkListWithImagesBlock = ({ imageItems }: { imageItems: Array<ModelCard> }): ReactElement => {
  function hasThumbnail(card: ModelCard): card is PageCard {
    return 'thumbnail' in card;
  }
  return (
    <ul>
      {imageItems.map((item, index) => {
        const tag = item.type === 'article' || item.type === 'journey' ? item.tag : null;
        return (
          <li key={item.id} className={getListItemsClassName(index, imageItems.length)}>
            <Link href={getModelURL(item as ModelCard)} analytics={{ context: 'Related Content', text: item.title }}>
              <div className="item-wrapper">
                <div className="image-wrapper">
                  {item.heroImage?.data ? (
                    <div className="field-linkimage">
                      <Image {...item.heroImage} isBackground />
                    </div>
                  ) : null}
                  {!item.heroImage?.data && hasThumbnail(item) && item.thumbnail?.data ? (
                    <div className="field-linkimage">
                      <Image {...item.thumbnail} isBackground />
                    </div>
                  ) : null}
                </div>
                <div className="text-wrapper">
                  {!isEmpty(item.title) && <div className="field-teasertext">{item.title}</div>}
                  <Tag tag={tag} />
                </div>
              </div>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export { LinkListWithImagesBlock };
